import axios from 'axios';
import swal from 'sweetalert2';

const hosname = window.location.hostname;

const instance = axios.create({
  // baseURL: hosname == 'localhost' ? 'http://localhost:3000/' : 'https://api.hexagonasia.com/',
  baseURL:
    hosname == 'localhost'
      ? 'http://localhost:3000/'
      : hosname == 'erp.hexagonasia.com'
      ? 'https://api.hexagonasia.com/'
      : 'https://api.hexagonasia.com/',

  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false
  // })
});

//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.defaults.headers = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  'Access-Control-Max-Age': '7200',
};

instance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    swal
      .fire({
        title: 'Error!',
        text: 'Please check your internt connection or contact administrator',
        icon: 'error',
        confirmButtonText: 'Cool',
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (
            !localStorage.getItem('CompaneyId') ||
            !localStorage.getItem('jwt_token')
          ) {
            localStorage.clear();
            window.location = '/session/signin';
          }
        }
      });

    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    swal
      .fire({
        title: 'Error!',
        text: 'Please check your internt connection or contact administrator',
        icon: 'error',
        confirmButtonText: 'Cool',
      })
      .then((result) => {
        if (
          !localStorage.getItem('CompaneyId') ||
          !localStorage.getItem('jwt_token')
        ) {
          localStorage.clear();
          window.location = '/session/signin';
        }
      });

    return Promise.reject(error);
  }
);

export default instance;
