import history from "@history.js";
import jwtAuthService from "../../services/jwtAuthService";
import { Auth } from "aws-amplify";

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export function setUserData(user) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
}

export function logoutUser() {
  localStorage.clear();
  return async (dispatch) => {
    try {
      await jwtAuthService.logout();
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log("error signing out: ", error);
    }

    window.location = "/session/signin";

    // history.push({
    //   pathname: "/session/signin",
    // });

    return dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}
