import { lazy } from "react";

const FieldDetails = lazy(() => import("./FieldDetails"));

const fielddetailsRoutes = [
  {
    path: "/fielddetails",
    component: FieldDetails,
  },
];

export default fielddetailsRoutes;
