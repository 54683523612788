import { lazy } from "react";

const Rla = lazy(() => import("./Rla"));

const rlaRoutes = [
  {
    path: "/rla",
    component: Rla,
  },
];

export default rlaRoutes;
