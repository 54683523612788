import { lazy } from "react";

const Signin = lazy(() => import("./Signin"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const ForgotPasswordCode = lazy(() => import("./ForgotPasswordCode"));

const Qrreader = lazy(() => import("./Qrreader"));

const DieselApp = lazy(() => import("./DieselApp"));






const Error404 = lazy(() => import("./Error"));

const sessionsRoutes = [
  {
    path: "/session/signin/:error?",
    component: Signin,
  },
  {
    path: "/session/reset",
    component: ForgotPassword,
  },

  {
    path: "/session/resetcode",
    component: ForgotPasswordCode,
  },

  {
    path: "/session/404",
    component: Error404,
  },

  {
    path: "/qrread",
    component: Qrreader,
  },

   {
    path: "/dapp/:code",
    component: DieselApp,
  },
];

export default sessionsRoutes;
