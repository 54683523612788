import { lazy } from "react";

const Vessel = lazy(() => import("./Vessel"));

const vesselRoutes = [
  {
    path: "/vessel",
    component: Vessel,
  },
];

export default vesselRoutes;
