import { lazy } from "react";

const FinalDrc = lazy(() => import("./FinalDrc"));

const finalDrcRoutes = [
  {
    path: "/finaldrc",
    component: FinalDrc,
  },
];

export default finalDrcRoutes;
