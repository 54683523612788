import { lazy } from "react";

const Chqprint = lazy(() => import("./Chqprint"));

const chqprintRoutes = [
  {
    path: "/chqprint",
    component: Chqprint,
  },
];

export default chqprintRoutes;
